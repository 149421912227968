var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tp-table-scroll__with-title tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":_vm.productGroupStatusRequest.value === 'loading-getProductTypes',"loading-text":"Đang tải dữ liệu","items":_vm.productGroups,"item-key":"id","show-select":""},on:{"click:row":function($event){return _vm.viewDetail($event)}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type === 1 ? "Khuyến mại" : "Khuyến mại")+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d_flex justify-center alight-center"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":hover ? 'grey darken-1' : 'grey lighten-1',"icon":""},on:{"click":function($event){return _vm.viewDetail(item)}}},[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"color":hover ? 'grey darken-1' : 'grey lighten-1',"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteProductGroup(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)})],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }