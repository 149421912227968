<template>
  <v-data-table
    v-model="selected"
    class="tp-table-scroll__with-title tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="productGroupStatusRequest.value === 'loading-getProductTypes'"
    loading-text="Đang tải dữ liệu"
    :items="productGroups"
    item-key="id"
    show-select
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.type`]="{ item }">
      {{ item.type === 1 ? "Khuyến mại" : "Khuyến mại" }}
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <div class="d_flex justify-center alight-center">
        <v-hover v-slot:default="{ hover }">
          <v-btn
            class="mr-2"
            :color="hover ? 'grey darken-1' : 'grey lighten-1'"
            icon
            @click="viewDetail(item)"
          >
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </v-hover>
        <v-hover v-slot:default="{ hover }">
          <v-btn
            :color="hover ? 'grey darken-1' : 'grey lighten-1'"
            icon
            @click.stop="deleteProductGroup(item)"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-hover>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    selectedProductGroups: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Tên nhóm",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Loại nhóm",
          align: "start",
          sortable: false,
          value: "type"
        },
        {
          text: "Mô tả",
          align: "start",
          sortable: false,
          value: "description"
        },
        {
          text: "Hành động",
          align: "center",
          sortable: false,
          value: "action"
        }
      ]
    };
  },
  computed: {
    productGroupConditions() {
      return this.$store.getters["PRODUCT_GROUP/productGroupConditions"];
    },
    productGroups() {
      return this.$store.getters["PRODUCT_GROUP/productGroups"];
    },
    productGroupStatusRequest() {
      return this.$store.getters["PRODUCT_GROUP/statusRequest"];
    },
    selected: {
      get() {
        return this.selectedProductGroups;
      },
      set(val) {
        this.$emit("updateSelectedProductGroups", val);
      }
    }
  },
  methods: {
    async deleteProductGroup(item) {
      // Confirm
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa nhóm sản phẩm <strong>"${item.name}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              const routeQuery = this.$route.query;

              await this.$store.dispatch("PRODUCT_GROUP/deleteProductGroups", {
                data: {
                  ids: [item.id]
                },
                routeQuery: {
                  search: routeQuery.search,
                  per_page: parseInt(routeQuery.limit),
                  cur_page: parseInt(routeQuery.page)
                }
              });
              if (
                this.productGroupStatusRequest.value ===
                "success-deleteProductGroups"
              ) {
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
              }
            }
          }
        }
      });
    },

    async viewDetail(item) {
      if (this.productGroupConditions.length === 0) {
        await this.$store.dispatch("PRODUCT_GROUP/getProductGroupConditions");
      }
      await this.$store.dispatch("PRODUCT_GROUP/getProductGroupById", item.id);
      this.$modal.show({ name: "modal-product-group-detail" });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
