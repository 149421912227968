<template>
  <tp-modal name="modal-product-group-detail" max-width="768px">
    <v-card flat>
      <v-card-title class="pb-4">
        <div class="font-weight-bold">Nhóm sản phẩm</div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="productGroup.id"
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          :loading="
            productGroupStatusRequest.value === 'loading-updateProductGroup'
          "
          @click="updateProductGroup()"
        >
          Lưu
        </v-btn>
        <v-btn
          v-else
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          :loading="
            productGroupStatusRequest.value === 'loading-createProductGroup'
          "
          @click="createProductGroup()"
        >
          Thêm
        </v-btn>
        <v-btn
          v-if="productGroup.id"
          icon
          color="red accent-1"
          @click="deleteProductGroup()"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card class="px-5 pt-5" flat>
          <v-row class="">
            <v-col class="py-0" cols="12">
              <div class="font-weight-bold mb-2">Tên nhóm</div>
              <v-text-field
                v-model="productGroup.name"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập khuyến mại"
                single-line
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12">
              <div class="font-weight-bold mb-2">Loại nhóm</div>
              <v-autocomplete
                v-model="productGroup.type"
                :value="1"
                class="tp-filter-autocomplete"
                :items="productGroupTypeList"
                dense
                deletable-chips
                hide-selected
                item-text="name"
                item-value="id"
                no-data-text="Không có dữ liệu"
                outlined
                single-line
                placeholder="Chọn loại nhóm"
                readonly
              ></v-autocomplete>
            </v-col>
            <v-col class="py-0" sm="12">
              <div class="font-weight-bold mb-2">Mô tả</div>
              <v-text-field
                v-model="productGroup.description"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập mô tả"
                single-line
              ></v-text-field>
            </v-col>

            <product-collecting></product-collecting>
          </v-row>
        </v-card>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import ProductCollecting from "./components/ProductCollecting";

export default {
  components: {
    ProductCollecting
  },
  data() {
    return {
      productGroupTypeList: [{ id: 1, name: "Khuyến mại" }]
    };
  },
  computed: {
    productGroup() {
      return this.$store.getters["PRODUCT_GROUP/productGroup"];
    },
    productGroupStatusRequest() {
      return this.$store.getters["PRODUCT_GROUP/statusRequest"];
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-product-group-detail" });
    },

    async createProductGroup() {
      const routeQuery = this.$route.query;
      const obj = { ...this.productGroup };

      // Map dks to array of id if collecting type is 1
      if (this.productGroup.list_type === 1) {
        obj.dks = obj.dks.map(item => item.id);
      }

      await this.$store.dispatch("PRODUCT_GROUP/createProductGroup", {
        data: obj,
        routeQuery: {
          search: routeQuery.search,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Alert
      if (
        this.productGroupStatusRequest.value === "success-createProductGroup"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm nhóm thành công"
          }
        });
      }
    },

    async deleteProductGroup() {
      // Confirm
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa nhóm sản phẩm <strong>"${this.productGroup.name}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              const routeQuery = this.$route.query;

              await this.$store.dispatch("PRODUCT_GROUP/deleteProductGroups", {
                data: {
                  ids: [this.productGroup.id]
                },
                routeQuery: {
                  search: routeQuery.search,
                  per_page: parseInt(routeQuery.limit),
                  cur_page: parseInt(routeQuery.page)
                }
              });
              if (
                this.productGroupStatusRequest.value ===
                "success-deleteProductGroups"
              ) {
                this.$modal.hide({ name: "modal-product-group-detail" });
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
              }
            }
          }
        }
      });
    },

    async updateProductGroup() {
      const routeQuery = this.$route.query;
      const obj = { ...this.productGroup };

      // Map dks to array of id if collecting type is 1
      if (this.productGroup.list_type === 1) {
        obj.dks = obj.dks.map(item => item.id);
      }

      await this.$store.dispatch("PRODUCT_GROUP/updateProductGroup", {
        data: obj,
        routeQuery: {
          search: routeQuery.search,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Alert
      if (
        this.productGroupStatusRequest.value === "success-updateProductGroup"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật"
          }
        });
      }
    }
  }
};
</script>
