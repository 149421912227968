<template>
  <v-col class="pt-0 pb-5" sm="12">
    <div class="font-weight-bold mb-2">Sản phẩm</div>
    <v-radio-group
      v-model="productGroup.list_type"
      class="mt-0"
      hide-details
      @change="updateListType()"
    >
      <v-radio :value="1" label="Sản phẩm cụ thể"></v-radio>
      <v-radio
        :value="2"
        label="Tự động nhóm sản phẩm theo điều kiện"
      ></v-radio>
    </v-radio-group>
    <!-- Start: Specific products -->
    <template v-if="productGroup.list_type === 1">
      <v-autocomplete
        class="tp-filter-autocomplete mt-4"
        :items="filterSearchProductOptions"
        clearable
        dense
        deletable-chips
        hide-selected
        hide-details
        item-text="product_name"
        item-value="id"
        no-data-text="Không có dữ liệu"
        no-filter
        outlined
        prepend-inner-icon="mdi-magnify"
        single-line
        placeholder="Tìm kiếm theo mã, tên sản phẩm hoặc SKU"
        return-object
        :search-input.sync="productSearchKey"
        @change="selectProductOption($event)"
      >
        <template v-slot:item="data">
          <v-list-item-content>
            <v-list-item-title
              v-html="data.item.product_name"
            ></v-list-item-title>
            <v-list-item-subtitle
              class="item-subtitle"
              v-html="data.item.name"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <div>
        <div class="font-weight-medium mt-4">
          Đã chọn ({{ productGroup.dks.length }})
        </div>
        <v-list class="py-0">
          <v-list-item
            class="tp-border-thin rounded px-3 mt-2"
            v-for="(item, index) in productGroup.dks"
            :key="index"
          >
            <v-list-item-content class="py-2">
              <v-list-item-title>
                {{ item.product_name }}
              </v-list-item-title>
              <div
                class="item-subtitle text-subtitle-2 font-weight-light grey--text text--darken-2"
                v-html="item.name"
              >
                {{ item.name }}
              </div>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon @click="removeSelectedProductOption(index)">
                <v-icon color="grey lighten-1">mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
    </template>
    <!-- End: Specific products -->
    <!-- Start: Automatic collecting -->
    <template v-else>
      <div>
        <div class="font-weight-medium mt-4 mb-2">
          Điều kiện nhóm sản phẩm
        </div>
        <div>
          <tp-input-condition
            v-for="(item, index) in productGroup.dks"
            :key="index"
            :attributes="productGroupConditions"
            :value="item"
            @change:name="item.name = $event"
            @change:db="item.db = $event"
            @change:condition="item.dk = $event"
            @change:type="item.type = $event"
            @change:value="item.list = $event"
            @remove="removeCondition(index)"
          ></tp-input-condition>
        </div>
        <v-btn
          class="rounded-lg mt-2"
          color="grey--text text--darken-2"
          depressed
          @click="addCondition()"
        >
          Thêm điều kiện
        </v-btn>
      </div>
    </template>
    <!-- End: Automatic collecting -->
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      productSearchKey: null
    };
  },
  computed: {
    filterSearchProductOptions() {
      const arr = this.productGroup.dks.map(item => item.id);

      return this.searchProductOptions.filter(item => !arr.includes(item.id));
    },
    productGroup() {
      return this.$store.getters["PRODUCT_GROUP/productGroup"];
    },
    productGroupConditions() {
      return this.$store.getters["PRODUCT_GROUP/productGroupConditions"];
    },
    searchProductOptions() {
      return this.$store.getters["PRODUCT_OPTION/searchProductOptions"];
    }
  },
  watch: {
    productSearchKey(val) {
      if (val && val.length > 0) {
        this.$store.dispatch("PRODUCT_OPTION/searchProductOptions", {
          search: val
        });
      }
    }
  },
  async created() {
    if (this.productGroupConditions.length === 0) {
      await this.$store.dispatch("PRODUCT_GROUP/getProductGroupConditions");
    }
  },
  methods: {
    addCondition() {
      this.productGroup.dks.push({
        name: null,
        db: null,
        dk: [],
        list: [],
        type: null
      });
    },

    removeCondition(index) {
      this.productGroup.dks.splice(index, 1);
    },

    removeSelectedProductOption(index) {
      this.productGroup.dks.splice(index, 1);
    },

    selectProductOption(item) {
      if (item && item.id) {
        this.productGroup.dks.unshift({ ...item });
      }
    },

    updateListType() {
      this.productGroup.dks = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.item-subtitle {
  ::v-deep p {
    margin-bottom: 0 !important;
  }
}
</style>
